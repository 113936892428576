import StompClientService from "../stompClientService";
import { session } from "websdk-core";

export const getUserHandler = (
  correlationId: string,
  replyToQueue: string | null
) => {
  try {
    session.retrieveUser().subscribe({
      next: (data: any) => {
        const userData = typeof data === "string" ? JSON.parse(data) : data;
        const response = JSON.stringify(userData);
        if (replyToQueue) {
          StompClientService.publish(replyToQueue, response, {
            "correlation-id": correlationId.toString(),
          });
        } else {
          console.error("No reply-to destination specified in message headers");
        }
      },
      error: (error: Error) => {
        const errorMessage =
          error instanceof Error ? error.message : "Unknown error";
        console.error("Error retrieving user data:", errorMessage);
        const response = JSON.stringify({ error: errorMessage });
        if (replyToQueue) {
          StompClientService.publish(replyToQueue, response);
        }
      },
    });
  } catch (err) {
    console.error("Unexpected error:", err);
  }
};
